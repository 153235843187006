import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Web3Context } from "../../contexts/Web3Context";
import NotePad from "./NotePad";
import PrimaryButton from "./PrimaryButton";

export default function Generating() {  
    const navigate = useNavigate()

    const {supply} = useContext(Web3Context)

    async function goToHogwash(){
        if(!supply){
            return
        }
        const r =  Math.ceil(Math.random() * supply);
        navigate(`/hogwash?token=${r}`)
    }
    return (
        <div className="d-flex justify-content-center">
            <NotePad>
            <div style={{"padding": "16px"}}></div>

                <div className="d-flex justify-content-center">
                        <h2 style={{"textAlign": "center"}}>Hoggy's coming up with your Hogwash!</h2>
                </div>
                <div style={{"padding": "16px"}}></div>
                <div className="d-flex justify-content-center">
                    <div style={{"width": "60%"}}>
                    <h4 style={{"textAlign": "center"}}>He should be done within 10 minutes, and once hes come up with some Hogwash he'll send you an email! In the meantime, explore some of his creations.</h4>

                    </div>

                </div>
                <div className="d-flex justify-content-center">
                    <PrimaryButton title={"View some Hogwash!"} onClick={goToHogwash} />

                </div>
                {/* <iframe width={"100%"} height={"400px"} src={"https://mentalcanvas.com/va/347182/scene"}></iframe> */}

            </NotePad>
        </div>
    )
}