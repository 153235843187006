import ky from "ky";
const ipfsTell = "ipfs://";
const httpsTell = "https://";
const ipfsGateway = "https://collectees.mypinata.cloud/ipfs/";

function formatIPFStoGateway(ipfsURI) {

  if (!ipfsURI) {
    return undefined;
  }
  let isIPFS = ipfsURI.includes(ipfsTell);
  let isHTTPS = ipfsURI.includes(httpsTell);
  let newURI;
  if (isHTTPS) {
    return ipfsURI;
  }

  if (isIPFS) {
    newURI = ipfsURI.replace(ipfsTell, ipfsGateway);

    return newURI;
  }

  return undefined;
}

async function getNFTJson(ipfsURI) {

  if (!ipfsURI) {
    return undefined;
  }
  let isIPFS = ipfsURI.includes(ipfsTell);
  let isHTTPS = ipfsURI.includes(httpsTell);
  let newURI;
  if (isHTTPS) {
    newURI = ipfsURI;
  }

  if (isIPFS) {
    newURI = ipfsURI.replace(ipfsTell, ipfsGateway);
  }
  if (!newURI) {
    return;
  }
  const resp = await ky.get(newURI, {throwHttpErrors: false}).json();
  return resp;
}

const utils = {
  formatIPFStoGateway,
  getNFTJson,
};

export default utils;
