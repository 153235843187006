import ky from "ky"

async function createHogwash(title, prompt, tempurature, email){
    let resp
    try{
        resp = await ky.post('/v1/createHogwash',
       {
         json: {
             title: title,
             prompt: prompt,
             tempurature,
             email: email,
         },
         throwHttpErrors: false
       }).json()
       return resp
       }catch(e){
         return {error: e}
       }
}

const api={
    createHogwash: createHogwash
}
export default api