import { useContext, useState } from "react"
import AutoTextArea from "./AutoTextArea"
import NotePad from "./NotePad"
import TextInput from "./TextInput"
import hoggy1 from '../../images/hoggy1.png'
import topDashStagger from '../topdash-stagger.png'
import sideDashStagger from '../side-dash-stagger.png'
import bottomDashStagger from '../bottomdash-stagger.png'
import downArrow from '../downArrow.png'
import hogwashButton from '../hogwash-button.svg'
import api from "../../utils/api"
import { useNavigate } from "react-router-dom";
import PrimaryButton from "./PrimaryButton"
import AuthenticatedWeb3 from "./AuthenticatedWeb3"
import { Web3Context } from "../../contexts/Web3Context"
import { FormControl, InputGroup } from "react-bootstrap"
import Web3 from "web3"


export default function Home() {
    const navigate = useNavigate();
    const [prompt, setPrompt] = useState("")
    const [email, setEmail] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [newPrice, setNewPrice] = useState("")
    const { account, hogwash, hogwashOwner, hogwashBalance, withdrawHandler, changePriceHandler, cost, supplyAvailable, currentNetwork } = useContext(Web3Context)


    async function createNewHogwash() {
        if (!prompt || prompt.length === 0) {
            window.alert("Error: No Prompt Provided")
            setIsLoading(false)
            return
        }
        if (!email || email.length === 0) {
            window.alert("Error: No Email Provided")
            setIsLoading(false)
            return
        }
        const resp = await api.createHogwash(undefined, prompt, 1, email)
        if (resp.error) {
            window.alert(resp.error)
            setIsLoading(false)
            return
        }
        //TODO: GO TO IS LOADING PAGE
        navigate("/generating");

    }

    return (
        <div>
            <div className="d-flex justify-content-center">
                <NotePad>
                    {account && account === hogwashOwner ?
                        <>
                            <div className="d-flex justify-content-center">
                                <div style={{ fontSize: "20px", fontWeight: "bold", textAlign: "center" }}>
                                    Balance: {hogwashBalance}ETH
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                                <PrimaryButton title={"Withdraw"} onClick={withdrawHandler}/>
                            </div>
                            <div className="d-flex justify-content-center">
                                <FormControl onChange={(e) => setNewPrice(e.target.value)} />
                                <PrimaryButton title={"Change Price"} onClick={()=> changePriceHandler(newPrice)}/>
                            </div>
                        </>
                        :
                        null
                    }
                    <div className="d-flex justify-content-center mobile-only">
                    <div className="header-text mobile-only" style={{width:"60%"}}>
                        Please use a Desktop to use HOGWASH!
                    </div>
                    </div>
                    <div className="header-text">
                        Meet Hoggy!
                    </div>
                    <div className="d-flex justify-content-center">
                        <img style={{ width: "240px" }} src={hoggy1} alt="hoggy" />
                    </div>
                    <div className="description-text-wrapper d-flex justify-content-center">
                        <div className="description-text">
                            {`Hoggy the drug addled swine! He's a great story teller (when he's tripping) so if you give him a prompt (and ${cost ? Web3.utils.fromWei(cost) : "some"} ${currentNetwork && currentNetwork.toString() === "137" ? "MATIC" : "ETH"}) he'll be able to churn out something REALLY spectacular for you!`}
                        </div>
                        <div>

                        </div>

                    </div>
                    <div className="d-flex justify-content-center">
                        <div className="text-field-label d-flex">
                            <img style={{ width: "60px" }} src={downArrow} />
                            <h4>* Your Prompt Here!</h4>
                        </div>
                    </div>

                    <AutoTextArea value={prompt} setValue={setPrompt} />
                    <div style={{ padding: "10px" }}></div>
                    <div className="description-text-wrapper d-flex justify-content-center">
                        <div className="description-text">
                            Once you're done with your prompt, it will take about 10 minutes for Hoggy to create your story! Once Hoggy finishes your story, he'll send you an email to mint your final story.
                        </div>
                        <div>

                        </div>

                    </div>
                    <div style={{ padding: "10px" }}></div>

                    <div className="d-flex justify-content-center">
                        <div className="text-field-label d-flex">
                            <img style={{ width: "60px" }} src={downArrow} />
                            <h4>* Your Email Here!</h4>
                        </div>
                    </div>

                    <TextInput value={email} setValue={setEmail} />
                    <div style={{ padding: "10px" }}></div>

                    { supplyAvailable === 0 && account && hogwash? <div className="d-flex justify-content-center" style={{"fontSize": "32px", "fontWeight": "bold"}}>
                        SOLD OUT!
                    </div>
                    :<AuthenticatedWeb3>
                    <div className="d-flex justify-content-center">
                        {isLoading ? "Loading..." : <PrimaryButton title={"Create some Hogwash!"} onClick={createNewHogwash} />
                        }
                    </div>
                </AuthenticatedWeb3>
                }
                    <div style={{ padding: "30px" }}></div>

                </NotePad>

            </div >


            <div style={{ padding: "30px" }}></div>

        </div>
    )
}