import { FormControl, InputGroup } from 'react-bootstrap'
import topDashStagger from '../topdash-stagger.png'
import bottomDashStagger from '../bottomdash-stagger.png'

import React, { useState, CSSProperties, useEffect, useRef } from "react";

export default function TextInput({hintText, value, setValue}) {
    const textAreaRef = useRef(null);
 

	const onChangeHandler = (event) => {
		setValue(event.target.value);
	};
    return (<>
        <div>
            <img src={topDashStagger} />
        </div>
        <div className="d-flex justify-content-center">
            <div className="side-dash">
                <div className="inner-dash" >

                    <InputGroup>
                        <FormControl onChange={onChangeHandler} 
            className="custom-text-area"  />
                    </InputGroup>
                </div>
            </div>
        </div>
        <div>
            <img src={bottomDashStagger} />
        </div>
    </>)
} 