import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { Web3Context } from "../../contexts/Web3Context"
import logo from '../../images/hogwash-logo-200h-white-outline.png'
import CONFIG from '../../config.json';
import { SocialIcon } from 'react-social-icons';

export default function NotePad({ children }) {
    const navigate = useNavigate()
    const { account, supplyAvailable, web3Handler, supply, ownedTokens, hogwash, currentNetwork, blockchainExplorerURL, openseaURL, balanceOf } = useContext(Web3Context)

    async function goToHogwash() {
        if (!supply) {
            return
        }
        const r = Math.ceil(Math.random() * supply);
        navigate(`/hogwash?token=${r}`)
    }

    async function goToNonsense() {
        navigate(`/nonsense`)
    }
    return (
        <div className="notepad">
            <div className="navbar notepad-nav">
                <a
                    className="navbar-brand col-sm-3 col-md-2 mr-0 mx-4"
                    style={{ height: "100%" }}
                    href="/"
                    rel="noopener noreferrer"
                >
                    <img src={logo} className="App-logo" alt="logo" />
                </a>
                {account && hogwash ?
                    <div style={{ color: "black", fontWeight: "bold", border: "1px solid black", padding: "6px" }}>
                        {`${supplyAvailable} left`}
                    </div>
                    : null}
                {
                    account ? (
                        <button onClick={goToHogwash} className="secondary-button">View Hogwash</button>
                    )
                        :
                        null
                }
                {
                    account && ownedTokens && ownedTokens.length > 0 ? (
                        <button onClick={goToNonsense} className="secondary-button">YOUR NONSENSE</button>

                    )
                        :
                        null
                }
                {account ? (
                    <a
                        href={`https://etherscan.io/address/${account}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="connect-wallet-button nav-button btn-sm mx-4">
                        {account.slice(0, 5) + '...' + account.slice(38, 42)}
                    </a>
                ) : (
                    <button onClick={web3Handler} className="connect-wallet-button nav-button btn-sm mx-4">Connect to Polygon</button>
                )}
            </div>
            {children}
            <div>
                {hogwash ?
                    <>
                        <div className="d-flex justify-content-center">
                            <div style={{ width: "60%" }}>
                                <div>
                                    {hogwash &&
                                        <a href={`${blockchainExplorerURL}address/${hogwash._address}`}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="contract-link d-block my-3">
                                            {hogwash._address}
                                        </a>
                                    }

                                    {CONFIG.NETWORKS[currentNetwork] && (
                                        <p>Current Network: {CONFIG.NETWORKS[currentNetwork].name}</p>
                                    )}

                                    <p>{`NFT's Left: ${supplyAvailable}, You've minted: ${balanceOf}`}</p>
                                    <a href={"https://opensea.io/collection/hogwash-polygon"}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="contract-link d-block my-3">
                                        Opensea
                                    </a>


                                </div>
                            </div>
                        </div>
                    </>
                    :
                    null
                }
            </div>
            <div className="d-flex justify-content-center">
                <SocialIcon url="https://discord.gg/P7p6fpx4aw" bgColor={"black"}/>
                <div style={{padding: "16px"}}></div>
                <SocialIcon url="https://twitter.com/hogwashapp" bgColor={"black"}/>
            </div>
            <div style={{padding: "16px"}}></div>

        </div>
    )
}