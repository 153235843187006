import { useState, useEffect } from 'react'
import { Row, Col, Form, InputGroup, FormControl } from 'react-bootstrap'
import Countdown from 'react-countdown'
import Web3 from 'web3'
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link
} from "react-router-dom";
// Import Images + CSS
import logo from '../images/hogwash-logo-200h.png'
import hoggy1 from '../images/hoggy1.png'

import { useNavigate } from "react-router-dom";

import topDashStagger from './topdash-stagger.png'
import sideDashStagger from './side-dash-stagger.png'
import bottomDashStagger from './bottomdash-stagger.png'
import downArrow from './downArrow.png'
import hogwashButton from './hogwash-button.svg'
import api from '../utils/api'
import './App.css'

// Import ABI + Config
import Hogwash from '../abis/HogWash.json';
import HogwashMinter from '../abis/HogwashMinter.json';

import CONFIG from '../config.json';
import AutoTextArea from './containers/AutoTextArea'
import TextInput from './containers/TextInput'
import NotePad from './containers/NotePad'
import { Web3Context } from '../contexts/Web3Context';
import Home from './containers/Home';
import Generating from './containers/Generating';
import Mint from './containers/Mint';
import HogwashViewer from './containers/HogwashViewer';
import YourStuff from './containers/YourStuff';

function App() {
	const navigate = useNavigate()
	const [web3, setWeb3] = useState(null)
	const [hogwash, setHogwash] = useState(null)
	const [hogwashMinter, setHogwashMinter] = useState(null)
	const [supplyAvailable, setSupplyAvailable] = useState(0)
	const [balanceOf, setBalanceOf] = useState(0)
	const [supply, setTotalSupply] = useState(null)
	const [cost, setCost] = useState(null)
	const [account, setAccount] = useState(null)
	const [currentNetwork, setCurrentNetwork] = useState(null)

	const [blockchainExplorerURL, setBlockchainExplorerURL] = useState('https://etherscan.io/')
	const [openseaURL, setOpenseaURL] = useState('https://opensea.io/collection/hogwash-polygon')

	const [isMinting, setIsMinting] = useState(false)
	const [isError, setIsError] = useState(false)
	const [message, setMessage] = useState(null)

	const [currentTime, setCurrentTime] = useState(new Date().getTime())
	const [revealTime, setRevealTime] = useState(0)

	const [prompt, setPrompt] = useState("")
	const [email, setEmail] = useState("")
	const [ownedTokens, setOwnedTokens] = useState(null)
	const [hogwashOwner, setHogwashOwner] = useState(null)
	const [hogwashBalance, setHogwashBalance] = useState(null)

	async function createNewHogwash() {
		const resp = await api.createHogwash(undefined, prompt, 1, email)
	}

	const loadBlockchainData = async () => {
		// Fetch Contract, Data, etc.
		if (web3) {
			const networkId = await web3.eth.net.getId()
			setCurrentNetwork(networkId)

			try {
				const hogwash = new web3.eth.Contract(Hogwash.abi, Hogwash.networks[networkId].address)
				const hogwashMinter = new web3.eth.Contract(HogwashMinter.abi, HogwashMinter.networks[networkId].address)
				
				setHogwash(hogwash)
				setHogwashMinter(hogwashMinter)
				const cost = await hogwash.methods.cost().call()
				const maxSupply = await hogwash.methods.maxSupply().call()
				const totalSupply = await hogwash.methods.totalSupply().call()
				setCost(cost)
				setTotalSupply(totalSupply)
				setSupplyAvailable(maxSupply - totalSupply)

				const balanceOf = await hogwash.methods.balanceOf(account).call()
				setBalanceOf(balanceOf)

				const allowMintingAfter = await hogwash.methods.allowMintingAfter().call()
				const timeDeployed = await hogwash.methods.timeDeployed().call()
				setRevealTime((Number(timeDeployed) + Number(allowMintingAfter)).toString() + '000')

				if (networkId !== 5777) {
					setBlockchainExplorerURL(CONFIG.NETWORKS[networkId].blockchainExplorerURL)
					setOpenseaURL(CONFIG.NETWORKS[networkId].openseaURL)
				}

				let tokenPromises = []
				for (let i = 0; i < balanceOf; i++) {
					tokenPromises.push(hogwash.methods.tokenOfOwnerByIndex(account, i).call())
				}
				let p = await Promise.all(tokenPromises)
				setOwnedTokens(p)
				const o = await hogwashMinter.methods.owner().call();
				setHogwashOwner(o)
				if (account && account === o) {
					const b = await web3.eth.getBalance(hogwashMinter.options.address)
					setHogwashBalance(web3.utils.fromWei(b))
				}


			} catch (error) {
				setIsError(true)
				setMessage("Contract not deployed to current network, please change network in MetaMask")
			}

		}
	}

	const loadWeb3 = async () => {
		if (typeof window.ethereum !== 'undefined' && !account) {
			const web3 = new Web3(window.ethereum, { transactionConfirmationBlocks: 1 })
			setWeb3(web3)

			const accounts = await web3.eth.getAccounts()

			if (accounts.length > 0) {
				setAccount(accounts[0])
			} else {
				setMessage('Please connect with MetaMask')
			}


			window.ethereum.on('accountsChanged', function (accounts) {
				setAccount(accounts[0])
				setMessage(null)
			});

			window.ethereum.on('chainChanged', (chainId) => {
				// Handle the new chain.
				// Correctly handling chain changes can be complicated.
				// We recommend reloading the page unless you have good reason not to.
				window.location.reload();
			});
		}
	}

	// MetaMask Login/Connect
	const web3Handler = async () => {
		if (web3) {
			const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
			setAccount(accounts[0])
		}
	}

	const withdrawHandler = async () => {
		if (hogwashOwner !== account) {

			return
		}
		await hogwashMinter.methods.withdraw().send({from:account})
	}
	const changePriceHandler = async (v) => {
		if (!v || v.length === 0) {
			console.log("no price")
			return
		}
		try{
			const weiValue = Web3.utils.toWei(v, 'ether');
			await hogwash.methods.setCost(weiValue).send({from:account})
		}catch(e){
			console.log(e)
		}
	}


	const mintNFTHandler = async (cid) => {
		if (revealTime > new Date().getTime()) {
			window.alert('Minting is not live yet!')
			return
		}



		// Mint NFT
		if (hogwash && hogwashMinter) {
			setIsMinting(true)
			setIsError(false)
			const cost = await hogwash.methods.cost().call()
			await hogwashMinter.methods.mint(cid).send({ from: account, value: cost })
				.on('receipt', async () => {
					try {
						const maxSupply = await hogwash.methods.maxSupply().call()
						const totalSupply = await hogwash.methods.totalSupply().call()
						setTotalSupply(totalSupply)

						setSupplyAvailable(maxSupply - totalSupply)

						const balanceOf = await hogwash.methods.balanceOf(account).call()
						setBalanceOf(balanceOf)

						let tokens = []
						let tokenPromises = []
						for (let i = 0; i < balanceOf; i++) {
							tokenPromises.push(hogwash.methods.tokenOfOwnerByIndex(account, i).call())
						}
						let p = await Promise.all(tokenPromises)
						setOwnedTokens(p)
						navigate(`/hogwash?token=${p[p.length - 1]}`)
					} catch (e) {
					}
				})
				.on('error', (error) => {
					window.alert(error.message.includes("CID is already minted") ? "ERROR: Hogwash has already been minted :(" : "Something went wrong...")
					setIsError(true)
				})
		}

		setIsMinting(false)
	};

	useEffect(() => {
		loadWeb3()
		loadBlockchainData()
	}, [account]);

	return (
		<>
			<Web3Context.Provider value={{
				account,
				setAccount,
				hogwash,
				hogwashMinter,
				web3,
				web3Handler,
				supply,
				setWeb3,
				mintNFTHandler,
				ownedTokens,
				setOwnedTokens,
				hogwashOwner,
				hogwashBalance,
				withdrawHandler,
				supplyAvailable,
				changePriceHandler,
				cost,
				currentNetwork,
				blockchainExplorerURL,
				balanceOf,
				openseaURL
			}}>
				<div>
					<Routes>
						<Route path="/nonsense" element={<YourStuff />} />
						<Route path="/hogwash" element={<HogwashViewer />} />
						<Route path="/mint" element={<Mint />} />
						<Route path="/generating" element={<Generating />} />
						<Route path="/" element={<Home />} />
					</Routes>
				</div>
			</Web3Context.Provider>
		</>
	)

	return (
		<div>
			<div className="d-flex justify-content-center">
				<NotePad>

				</NotePad>
				<div className="notepad">
					<div className="navbar notepad-nav">
						<a
							className="navbar-brand col-sm-3 col-md-2 mr-0 mx-4"
							style={{ height: "100%" }}
							href="http://www.hogwash.app/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img src={logo} className="App-logo" alt="logo" />
						</a>
						{account ? (
							<a
								href={`https://etherscan.io/address/${account}`}
								target="_blank"
								rel="noopener noreferrer"
								className="connect-wallet-button nav-button btn-sm mx-4">
								{account.slice(0, 5) + '...' + account.slice(38, 42)}
							</a>
						) : (
							<button onClick={web3Handler} className="connect-wallet-button nav-button btn-sm mx-4">Connect Wallet</button>
						)}
					</div>
					<div className="header-text">
						Meet Hoggy!
					</div>
					<div className="d-flex justify-content-center">
						<img style={{ width: "240px" }} src={hoggy1} alt="hoggy" />
					</div>
					<div className="description-text-wrapper d-flex justify-content-center">
						<div className="description-text">
							Hoggy the drug addled swine! He's a great story teller (when he's tripping) so if you give him a prompt he'll be able to churn out something REALLY spectacular for you!
						</div>
						<div>

						</div>

					</div>
					<div className="d-flex justify-content-center">
						<div className="text-field-label d-flex">
							<img style={{ width: "60px" }} src={downArrow} />
							<h4>* Your Prompt Here!</h4>
						</div>
					</div>

					<AutoTextArea value={prompt} setValue={setPrompt} />
					<div style={{ padding: "10px" }}></div>
					<div className="description-text-wrapper d-flex justify-content-center">
						<div className="description-text">
							Once you're done with your prompt, it will take about 10 minutes for Hoggy to create your story! Once Hoggy finishes your story, he'll send you an email to mint your final story.
						</div>
						<div>

						</div>

					</div>
					<div style={{ padding: "10px" }}></div>

					<div className="d-flex justify-content-center">
						<div className="text-field-label d-flex">
							<img style={{ width: "60px" }} src={downArrow} />
							<h4>* Your Email Here!</h4>
						</div>
					</div>

					<TextInput value={email} setValue={setEmail} />
					<div style={{ padding: "10px" }}></div>

					<div className="d-flex justify-content-center">
						<img onClick={() => { createNewHogwash() }} className="hogwash-button" src={hogwashButton} width={"200px"} />
					</div>
					<div style={{ padding: "30px" }}></div>

				</div>
			</div >

			{/* <main>
				<Row className="my-3">
					<Col className="text-center">
						<h1 className="text-uppercase">Open Emojis</h1>
						<p className="countdown">
							{revealTime !== 0 && <Countdown date={currentTime + (revealTime - currentTime)} />}
						</p>
						<p>Welcome! Mint your free emoji (not including gas fees) on 11/04/21</p>
					</Col>
				</Row>
				<Row className="my-4">
					<Col className="panel grid" sm={12} md={6}>
						<button onClick={mintNFTHandler} className="button mint-button"><span>Mint</span></button>
					</Col>
					<Col className="panel grid image-showcase mx-4">
						<img
							src={isError ? (
								sadImage
							) : !isError && isMinting ? (
								excitedImage
							) : (
								happyImage
							)}
							alt="emoji-smile"
							className="image-showcase-example-1"
						/>
					</Col>
				</Row>
				<Row className="my-3">
					<Col className="flex">
						<a href={openseaURL + account} target="_blank" rel="noreferrer" className="button">View My Opensea</a>
						<a href={`${blockchainExplorerURL}address/${account}`} target="_blank" rel="noreferrer" className="button">My Etherscan</a>
					</Col>
				</Row>
				<Row className="my-2 text-center">
					{message ? (
						<p>{message}</p>
					) : (
						<div>
							{openEmoji &&
								<a href={`${blockchainExplorerURL}address/${openEmoji._address}`}
									target="_blank"
									rel="noreferrer"
									className="contract-link d-block my-3">
									{openEmoji._address}
								</a>
							}

							{CONFIG.NETWORKS[currentNetwork] && (
								<p>Current Network: {CONFIG.NETWORKS[currentNetwork].name}</p>
							)}

							<p>{`NFT's Left: ${supplyAvailable}, You've minted: ${balanceOf}`}</p>
						</div>
					)}
				</Row>
			</main> */}
			<div style={{ padding: "30px" }}></div>

		</div>
	)
}

function MyApp() {
	return (
		<Router>
			<App />
		</Router>
	)
}

export default MyApp;
