import { useContext } from "react";
import { Web3Context } from "../../contexts/Web3Context";

export default function AuthenticatedWeb3({children}){
    const {account, hogwash, hogwashMinter, web3Handler} = useContext(Web3Context)

    if(!account){
        return (
            <>
                <div className="d-flex justify-content-center">
                    <div>
                        <div style={{"textAlign": "center"}}></div>
                        <button onClick={web3Handler} className="connect-wallet-button nav-button btn-sm mx-4">Connect to Polygon</button>
                    </div>
                </div>
            </>
        )
    }

    if(!hogwash || !hogwashMinter){
        return (
            <>
                <div className="d-flex justify-content-center">
                    <div style={{"textAlign": "center", "fontSize": "24px"}}>
                        Please Connect to the Polygon network to use the app :P
                    </div>
                </div>
            </>
        )
    }

    return(
        <>
            {children}
        </>
    )

}