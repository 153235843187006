
import Interweave from "interweave";
import { useContext, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Web3Context } from "../../contexts/Web3Context";
import utils from "../../utils/utils";
import NotePad from "./NotePad";
import PrimaryButton from "./PrimaryButton";

export default function Mint() {
    let [searchParams, setSearchParams] = useSearchParams();
    const [cid, setCid] = useState(searchParams.get("cid"))
    const [prompt, setPrompt] = useState(searchParams.get("prompt"))
    const [html, setHtml] = useState(undefined)
    const [isLoading, setIsLoading] = useState(true)
    const { mintNFTHandler, account, web3Handler, hogwash, hogwashMinter } = useContext(Web3Context)
    const [error, setError] = useState(undefined)
    const [isMintedAlready, setIsMintedAlready] = useState(false)

    async function mintNFT() {
        setIsLoading(true)
        if (!cid) {
            window.alert("Something went wrong...")
            setIsLoading(false)
            return
        }

        let resp = await mintNFTHandler(cid)

    }

    async function getData() {
        setError(undefined)
        setIsLoading(true)
        if (!cid) {
            window.alert("Error: Something went wrong...")
            return
        }
        let ipfsUri = "ipfs://" + cid
        const resp = await utils.getNFTJson(ipfsUri)
        if (!resp) {
            window.alert("Error: Something went wrong...")
            return
        }
        if (!resp.animation_url) {
            window.alert("Error: Something went wrong...")
            return
        }
        const resp2 = await fetch(utils.formatIPFStoGateway(resp.animation_url.replace(".html", "")))
        if (!resp2 || resp2.status !== 200) {
            window.alert("Error: Something went wrong...")
            return
        }
        var template = await resp2.text();
        if (!template.includes(prompt)) {
            window.alert("Error: Something went wrong...")
            return
        }
        let _isMintedAlready = await hogwash.methods.mintedCids(cid).call()
        if (_isMintedAlready) {
            setIsMintedAlready(true)
        }
        setHtml(template)
        setIsLoading(false)

    }
    useEffect(() => {
        if (account && hogwash) {
            getData()
        }
    }, [account, hogwash])

    return (
        <div className="d-flex justify-content-center">
            <NotePad>
                <div style={{ "textAlign": "center" }}>
                    {error}
                </div>
                <div className="d-flex justify-content-center">
                    <div style={{ width: "60%" }}>
                        <div style={{ height: "32px" }}></div>
                        <h2 style={{ "textAlign": "center" }}>
                            Your Hogwash is Ready!
                        </h2>
                        <div style={{ height: "16px" }}></div>

                        <h5 style={{ "textAlign": "center" }}>
                            Your Hogwash Prompt:
                        </h5>
                        <div style={{ height: "8px" }}></div>

                        <h4 style={{ "textAlign": "center", "fontWeight": "bold" }}>
                            {`"${prompt}..."`}
                        </h4>
                        <div style={{ height: "32px" }}></div>

                        <h4 style={{ "textAlign": "center", "fontWeight": "bold" }}>
                            To view your Hogwash, mint your Hogwash NFT!
                        </h4>
                        <div style={{ height: "32px" }}></div>

                        {account ?
                            <>
                                {
                                    hogwash && hogwashMinter ?
                                        <>
                                            <div className="d-flex justify-content-center">
                                                {isLoading ? "Loading..." : <>{!isMintedAlready ? <PrimaryButton title={"Mint Hogwash"} onClick={mintNFT} /> : <div style={{ fontWeight: "bold", fontSize: "32px", textAlign: "center" }}>This Hogwash was already minted...</div>}</>
                                                }
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div style={{ height: "8px" }}></div>
                                            <h2 style={{ textAlign: "center" }}>Hogwash isn't on this network :( Please Connect to the Polygon Network</h2>
                                        </>
                                }
                            </>
                            :
                            <>
                                <h4 style={{ textAlign: "center" }}>
                                    To Mint your Hogwash, you must connect your wallet to the Polygon network.

                                </h4>
                                <div className="d-flex justify-content-center">

                                    <button onClick={web3Handler} className="connect-wallet-button nav-button btn-sm mx-4">Connect to Polygon</button>


                                </div>
                            </>
                        }
                    </div>
                </div>
            </NotePad>
        </div>
    )

}