
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Web3Context } from "../../contexts/Web3Context";
import utils from "../../utils/utils";
import NotePad from "./NotePad";

export default function YourStuff(){
    const {ownedTokens} = useContext(Web3Context) 
   
    return (
        <div className="d-flex justify-content-center">
            <NotePad>
                <div style={{"padding": "12px"}}></div>
                <div className="d-flex justify-content-center" >
                    {ownedTokens ? 
                    <div style={{"width": "60%"}}>
                    {ownedTokens.map((e)=><TokenListItem key={e} tokenId={e} />)}
                </div>
                :
                <></>
                    }
                </div>
            </NotePad>
        </div>)
}

function TokenListItem({tokenId}){
    const navigate = useNavigate()
    const {hogwash} = useContext(Web3Context)
    const [metadataJson, setMetadataJson] = useState(undefined)
    async function getToken(){
        const tokenURI = await hogwash.methods.tokenURI(tokenId).call()

        const resp = await utils.getNFTJson(tokenURI)
        if(!resp){
            return
        }
        setMetadataJson(resp)
    }

    useEffect(() => {
        getToken()
    }, [])

    function goToHogwash(){
        navigate(`/hogwash?token=${tokenId}`)
    }
    if(!metadataJson){
        return <div></div>
    }
    return(
        <div style={{"fontSize": "20px", "padding": "6px"}} onClick={goToHogwash}>
            {`Token ${tokenId}: `}{`${metadataJson.attributes.filter((e) => e.trait_type === "prompt")[0].value.slice(0,40)}...`}
        </div>
    )
}