
import Interweave from "interweave";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Web3Context } from "../../contexts/Web3Context";
import utils from "../../utils/utils";
import AuthenticatedWeb3 from "./AuthenticatedWeb3";
import NotePad from "./NotePad";
import SecondaryButton from "./SecondaryButton";

export default function HogwashViewer() {
    const navigate = useNavigate()
    let [searchParams, setSearchParams] = useSearchParams();
    const [token, setToken] = useState(searchParams.get("token"))

    const [prompt, setPrompt] = useState(searchParams.get("prompt"))
    const [html, setHtml] = useState(undefined)
    const [isLoading, setIsLoading] = useState(true)
    const { mintNFTHandler, account, web3Handler, hogwash, hogwashMinter, supply } = useContext(Web3Context)
    const [error, setError] = useState(undefined)
    const [font, setFont] = useState("Gunny")

    async function randomizeHogwash(){
        const rand = Math.ceil(Math.random() * supply)
        navigate("/hogwash?token=" + rand.toString())
    }

    function toggleFont(){
        if(font ==="Gunny"){
            setFont("Times")
        } else {
            setFont("Gunny")
        }
    }


    async function getData() {
        setError(undefined)
        setIsLoading(true)
        let t  =searchParams.get("token")
        setToken(t)
        if (!account) {
            return
        }

        if (!hogwash) {
            return
        }

        if (!t) {
            window.alert("Error: Something went wrong...")
            return
        }

        if(t.toString() === "undefined"){
            navigate("/nonsense")
        }
        

        if (parseInt(t) > parseInt(supply) || parseInt(t) === 0) {
            window.alert("Error: Hogwash Doesn't Exist")
            return
        }

        const tokenURI = await hogwash.methods.tokenURI(t).call()

        const resp = await utils.getNFTJson(tokenURI)
        if (!resp) {
            window.alert("Error: Something went wrong...")
            return
        }
        if (!resp.animation_url) {
            window.alert("Error: Something went wrong...")
            return
        }
        const resp2 = await fetch(utils.formatIPFStoGateway(resp.animation_url.replace(".html", "")))
        if (!resp2 || resp2.status !== 200) {
            window.alert("Error: Something went wrong...")
            return
        }
        var template = await resp2.text();

        setHtml(template)
        setIsLoading(false)

    }
    useEffect(() => {
        getData()
    }, [account, hogwash, searchParams])

    return (
        <>
            <div className="d-flex justify-content-center">
                <NotePad>
                    <AuthenticatedWeb3>
                    <div style={{ "textAlign": "center" }}>
                        {error}
                    </div>
                    <div className="d-flex justify-content-center">
                        <div style={{ width: "60%" }}>
                            <div style={{ padding: "16px" }}></div>
                            <div className="d-flex justify-content-between">
                                <div style={{ "fontSize": "32px", "fontWeight": "bold" }}>{`Token ${token}`}</div>
                                <SecondaryButton onClick={toggleFont} title={"Toggle Font"}/>
                                <SecondaryButton onClick={randomizeHogwash} title={"View Random Hogwash"}/>
                            </div>
                            {html ?
                                <div style={{"fontFamily": font}}>
                                    <Interweave content={html.replace("font-size:12px", "font-size:20px")} />
                                </div>
                                :
                                <div>Hogwash does not exist</div>
                            }
                            <div style={{ padding: "16px" }}></div>

                        </div>
                    </div>
                    </AuthenticatedWeb3>
                </NotePad>

            </div>
            <div style={{ padding: "32px" }}></div>

        </>
    )

}