import { FormControl, InputGroup } from 'react-bootstrap'
import topDashStagger from '../topdash-stagger.png'
import bottomDashStagger from '../bottomdash-stagger.png'

import React, { useState, CSSProperties, useEffect, useRef } from "react";

export default function AutoTextArea({hintText, value, setValue}) {
    const textAreaRef = useRef(null);
    const [text, setText] = useState("");
    const [textAreaHeight, setTextAreaHeight] = useState("auto");
    const [parentHeight, setParentHeight] = useState("auto");
    const parentStyle = {
        minHeight: parentHeight,
    };

    const textAreaStyle = {
        height: textAreaHeight,
    };
    useEffect(() => {
		setParentHeight(`${textAreaRef.current.scrollHeight}px`);
		setTextAreaHeight(`${textAreaRef.current.scrollHeight}px`);
	}, [text]);

	const onChangeHandler = (event) => {
		setTextAreaHeight("auto");
		setParentHeight(`${textAreaRef.current.scrollHeight}px`);
        
		setText(event.target.value.substring(0,750));
        setValue(event.target.value.substring(0,750))
	};
    return (<>
        <div>
            <img src={topDashStagger} />
        </div>
        <div className="d-flex justify-content-center">
            <div className="side-dash">
                <div className="inner-dash" style={parentStyle}>

                    <InputGroup>
                        <FormControl onChange={onChangeHandler} ref={textAreaRef}
                        value={value}
                            style={textAreaStyle} className="custom-text-area" as="textarea" aria-label="With textarea"  />
                    </InputGroup>
                </div>
            </div>
        </div>
        <div>
        
        <div className="d-flex justify-content-center">

            <img src={bottomDashStagger} />
            </div>
        </div>
        <div style={{padding: "8px"}}></div>
        <div className="d-flex justify-content-center">
        <div style={{width: "60%"}}>
        <div className="d-flex justify-content-end">
            {`${value.length}/750`}
        </div>
        </div>
        </div>
        
    </>)
} 